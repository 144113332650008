import React from 'react';
import { Container } from 'emotion-flex';
import { SEO } from '../../components';
import { withLayout } from '../../components/Layout';

const AnmeldungPage = () => (
  <Container>
    <SEO title="Anmeldung Bestätigen" />
    <div
      css={{
        margin: '48px auto',
        maxWidth: 960,
        textAlign: 'center',
      }}
    >
      <h2>Schaue jetzt in dein Email-Postfach</h2>
      <p>
        Fast fertig, nun musst du deine Email-Adresse bestätigen. Klicke dazu
        bitte auf den Button in der Email, die wir dir gerade gesendet haben.
        Bis gleich{' '}
        <span role="img" aria-label="slightly smiling face">
          🙂
        </span>
      </p>
    </div>
  </Container>
);

export default withLayout(AnmeldungPage, { isNewLayout: true });
